import React from 'react';
import '../styles.css';
import './contact.css';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Contact() {
  const location = useLocation();
  console.log('location data', location.state);

  return (
    <div className="contact_main">
      <div className="container">
        <div className="contact_part">
          <div className="contact_box">
            <div className="text-center">
              <img src="/images/logo.png" alt="" className="logo"></img>
            </div>
            <div>
              <p className="mt-4">
                Your zip code isn&apos;t in our coverage area. Please call us to schedule your appointment at:
              </p>
              <a className="phone_cal mt-3" href="tel:18885886402">
                1-888-588-6402
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
