import React from 'react';
import '../styles.css';
import './scheduled.css';
import Screen from '../../screen';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import AlreadyScheduled from '../../component/AlreadyScheduled';

export default function Scheduled() {
  const location = useLocation();
  console.log('location data', location.state);

  return (
    <div className="main_bg">
      <div className="container">
        <div className="row main_pad">
          <div className="col-lg-6 mt-3 hideme">
            <Screen />
          </div>

          <div className="col-lg-6 mt-3 centerme">
            <img src="/images/logo.png" alt="" className="logo"></img>
            <AlreadyScheduled />
          </div>
        </div>
      </div>
    </div>
  );
}
