import React from 'react';
import '../styles.css';
import './registration.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Screen from '../../screen';
import PersonalInfoComp from '../../component/PersonalInfoComp';
const PersonalInfo = () => {
  return (
    <div className="main_bg">
      <div className="container">
        <div className="row main_pad">
          <div className="col-lg-6 mt-3 hideme">
            <Screen />
          </div>

          <div className="col-lg-6 mt-3 centerme">
            <img src="/images/logo.png" alt="" className="logo"></img>
            <PersonalInfoComp />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
