import React, { useState } from 'react';
import '../pages/login/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../pages/confirmation/Services';
import { useHistory } from 'react-router-dom';
import CopyrightText from './CopyrightText';

const PhoneConfirmation = () => {
  const history = useHistory();
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const phone = localStorage.getItem('phone');
      const params = {
        contact: phone,
        code: phoneVerificationCode
      };
      api
        .verifyCode(params)
        .then((res) => {
          return res;
        })
        .then((response) => {
          if (response.data.verifyCode.status === 'approved' && response.data.verifyCode.valid) {
            history.push('/schedule');
          }
        })
        .catch((err) => {
          console.log('error in handleSubmit', err);
          return err;
        });
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  return (
    <div>
      <div className="">
        <div className="email_box">
          <h1 className="email-h1">
            Quickly Schedule Your
            <div>Free Consultation</div>
          </h1>

          <p className="email_p">
            Your Information will remain confidential and will only be used by
            <br />
            <strong>Florida Window & Door</strong>
          </p>
          <p className="confirm_p">
            A confirmation code is sent to your provided contact number. Please enter 6 verification code{' '}
          </p>
          <form onSubmit={handleSubmit}>
            <label className="put-email">
              <input
                value={phoneVerificationCode}
                className="field_area"
                placeholder="123456"
                type="text"
                required
                name="name"
                onChange={(e) => setPhoneVerificationCode(e.target.value)}
              />

              <button type="submit" className="email_btn">
                Confirm
              </button>
            </label>
          </form>
        </div>
        <CopyrightText />
      </div>
    </div>
  );
};

export default PhoneConfirmation;
