import React from 'react';
import '../pages/login/login.css';

const screen = () => {
  return (
    <div>
      <div className="text_area">
        <img src="/images/logo.png" alt="" className="logo"></img>
        <h1 className="schedule-h1">Schedule Your Appointment Today.</h1>
        <p className="no-cost_text">
          No-cost and no-obligation consultation with a Florida Window &amp; Door specialist.
        </p>
        <div className="dot_area">
          <span className="dot"></span>
          <p className="dot_p">Enter your contact information.</p>
        </div>
        <div className="dot_area">
          <span className="dot"></span>
          <p className="dot_p">Choose a date and time that&apos;s convenient for you.</p>
        </div>
        <div className="dot_area">
          <span className="dot"></span>
          <p className="dot_p">
            Meet with our specialist and discover why we&apos;re
            <br />
            one of the nation&apos;s largest and most trusted
            <br />
            impact window and door companies.
          </p>
        </div>
        <img src="../images/loginbg.png" alt="" className="email_bg_img"></img>
      </div>
    </div>
  );
};

export default screen;
