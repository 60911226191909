import { backendApiUrl } from '../../globals';

const axios = require('axios');

const loginServiceUrl = `${backendApiUrl}/user`;

class ApiService {
  async sendVerificationCode(data) {
    try {
      const response = await axios.post(`${loginServiceUrl}/contact/code`, data);
      return response;
    } catch (e) {
      return e;
    }
  }

  async verifyCode(data) {
    try {
      const response = await axios.post(`${loginServiceUrl}/contact/code/verify`, data);
      return response;
    } catch (e) {
      return e;
    }
  }
}

export default new ApiService();
