import React, { useEffect } from 'react';
import Login from './pages/login';
import ConfirmCode from './pages/confirmation';
import ConfirmPhoneCode from './pages/phone_confirmation';
import Schedule from './pages/schedule';
import PersonalInfo from './pages/registration';
import PhoneComp from './pages/phone';
import Scheduled from './pages/scheduled';
import AlreadyScheduled from './pages/already_scheduled';
import Contact from './pages/contact';
import ContactError from './pages/contact-error';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import * as Fathom from 'fathom-client';

const FathomTracker = () => {
  const location = useLocation();

  useEffect(() => {
    Fathom.load('RFHLHFAU', {
      url: 'https://cdn.usefathom.com/script.js',
      includedDomains: ['portal.floridawindowanddoor.com']
    });

    Fathom.trackPageview();
  }, [location]); // Triggered when route changes

  return null; // Component does not render anything
};

const App = () => {
  return (
    <div>
      <Router>
        <FathomTracker />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/phone" component={PhoneComp} />
          <Route exact path="/confirm-code" component={ConfirmCode} />
          <Route exact path="/confirm-phone-code" component={ConfirmPhoneCode} />
          <Route exact path="/person-info" component={PersonalInfo} />
          <Route exact path="/schedule" component={Schedule} />

          <Route exact path="/appointment-status" component={Scheduled} />
          <Route exact path="/already-scheduled" component={AlreadyScheduled} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/contact-error" component={ContactError} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
