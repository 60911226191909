import React, { useState } from 'react';
import '../pages/login/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../pages/phone/Services';
import verificationApi from '../pages/confirmation/Services';
import { useHistory } from 'react-router-dom';
import CopyrightText from './CopyrightText';
const PhoneComp = () => {
  const [phone, setPhone] = useState('');

  const history = useHistory();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const params = {
        phone: phone,
        email: localStorage.getItem('email')
      };
      api
        .UserPhone(params)
        .then((res) => {
          return res;
        })
        .then((response) => {
          if (response.status === 200 && response.data.user) {
            localStorage.setItem('phone', phone);
            // now send verification code
            verificationApi
              .sendVerificationCode({ contact: phone, channel: 'sms' })
              .then((code) => {
                if (code) {
                  history.push('/confirm-phone-code');
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            history.push('/appointment-status');
          }
        })
        .catch((err) => {
          console.log('error in handleSubmit', err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="">
        <div className="email_box">
          <h1 className="email-h1">
            Quickly Schedule Your
            <div>Free Consultation</div>
          </h1>
          <p className="email_p">
            Your Information will remain confidential and will only be used by
            <br />
            <strong>Florida Window &amp; Door</strong>
          </p>
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex' }}>
              <span className="email_input" style={{ cursor: 'pointer' }}>
                Phone
              </span>
            </div>

            <label className="put-email">
              <input
                value={phone}
                className="field_area"
                placeholder="234 - 567 - 8901"
                type="phone"
                name="name"
                required
                onChange={(e) => setPhone(e.target.value)}
              />
              <button type="submit" className="email_btn">
                Continue
              </button>
            </label>
          </form>
        </div>
        <CopyrightText />
      </div>
    </div>
  );
};

export default PhoneComp;
