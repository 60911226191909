import React from 'react';
import './styles.css';
import { useState } from 'react';
import Calendar from './Calendar';
import PropTypes from 'prop-types';

export default function App(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  return (
    <div className="App">
      <Calendar
        onChangeHandler={props.onChangeHandler}
        showDetailsHandle={showDetailsHandle}
        firstTimeBlockDate={props.firstTimeBlockDate}
      />
      <br />
      {/* {showDetails && <Details data={data} />} */}
    </div>
  );
}

App.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  firstTimeBlockDate: PropTypes.any.isRequired
};
