import React, { useState, useEffect } from 'react';
import '../pages/login/login.css';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../pages/login/Services';
import { useHistory } from 'react-router-dom';
import CopyrightText from './CopyrightText';
import { DateTime } from 'luxon';

const EmailComp = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');

  const history = useHistory();
  localStorage.clear();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.getPropsect({ email, referrer: document?.referrer || 'notfound' });

      if (response.status !== 200 && response.data.includes('zip')) {
        history.push('/contact');
        return;
      }
      let prospectData = false;
      if (response.status === 200) {
        prospectData = response.data;
      }

      setIsLoading(false);

      // localStorage.setItem('sourceUrl', srcUrl);
      localStorage.setItem('currentUrl', currentUrl);

      const appointmentStatus = prospectData.appointmentStatus;
      switch (appointmentStatus) {
        case null:
          localStorage.setItem('email', email);
          history.push('/person-info');
          break;
        case 'No Future appointment scheduled.':
          localStorage.setItem('email', email);
          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              name: prospectData.name,
              address: prospectData.address,
              zipCode: prospectData.postalcode,
              email: prospectData.email,
              phone: prospectData.phone
            })
          );
          history.push('/schedule');
          break;
        case 'Future appointment is scheduled.':
          localStorage.setItem('user', JSON.stringify({ name: prospectData.name }));
          localStorage.setItem(
            'appointment',
            JSON.stringify({
              date: prospectData.appointmentDate,
              startTime: prospectData.startTime,
              endTime: prospectData.endTime
            })
          );
          history.push('/already-scheduled');
        // setError('Appointment already scheduled');
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    // setSrcUrl(document.referrer);
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <div>
      <div className="">
        <div className="email_box">
          <h1 className="email-h1">
            Quickly Schedule Your
            <div>Free Consultation</div>
          </h1>

          <ul className="email_list">
            <li>No money down.</li>
            <li>No payments until {DateTime.now().plus({ year: 2 }).toFormat('yyyy')}.</li>
            <li>Lifetime warranty.</li>
            <li>All products are Category-5 rated!</li>
            <li>Best price guarantee.</li>
          </ul>

          {/* <p className="email_p">
          &#10004; Your Information will remain confidential and will only be used by<br />
            <strong>Florida Window &amp; Door</strong>
  </p> */}
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex' }}>
              <span className="email_input" style={{ cursor: 'pointer' }}>
                Email
              </span>
            </div>

            <label className="put-email">
              <input
                value={email}
                className="field_area"
                placeholder="Johnsmith@example.com"
                type="email"
                required
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span style={{ marginLeft: '20px' }}>{error}</span>

              <button type="submit" className="email_btn">
                {isLoading ? (
                  <Spinner style={{ width: '2rem', height: '2rem' }} color="light">
                    &nbsp;
                  </Spinner>
                ) : (
                  'Continue'
                )}
              </button>
            </label>
          </form>
          <p className="email_p">
            You agree you would like to learn about our promotions &amp; you understand we use an automatic phone system
            and text messages to reach you. All calls and in-person meetings will be recorded for quality and training
            purposes. Your Information will only be used by <br />
            <strong>Florida Window &amp; Door</strong>
          </p>
        </div>
        <CopyrightText />
      </div>
    </div>
  );
};

export default EmailComp;
