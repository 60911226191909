import React, { useState } from 'react';
import url from 'url';
import '../assets/comStyles.css';
import { Spinner } from 'reactstrap';
import api from '../pages/login/Services';
import verificationApi from '../pages/confirmation/Services';
import { useHistory } from 'react-router-dom';
import CopyrightText from './CopyrightText';
const PersonalInfoComp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const INITIAL_STATE = {
    name: '',
    address: '',
    zipCode: '',
    email: '',
    phone: ''
  };

  const history = useHistory();
  const email = localStorage.getItem('email');
  // const sourceUrl = localStorage.getItem('sourceUrl');
  const currentUrl = localStorage.getItem('currentUrl');
  const [values, setValues] = React.useState(INITIAL_STATE);

  const onChangeText = async (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // localStorage.setItem('name', values.name);
    setIsLoading(true);

    // extract host name and source type from referrer url and landing page url respectively
    let sourceName = '';
    let sourceType = '';
    // if (sourceUrl) {
    //     const parsedUrl = url.parse(sourceUrl, true);
    //     let domainName = parsedUrl.hostname;

    //     const result = domainName.split('.');
    //     if (result.length === 3)
    //         sourceName = result[1];
    //     else if (result.length === 2)
    //         sourceName = result[0];
    // }
    if (currentUrl) {
      const parsedUrl = url.parse(currentUrl, true);
      sourceType = parsedUrl.query.sourceType;
      sourceName = parsedUrl.query.source;
    }

    try {
      const params = {
        name: values.name,
        address: values.address,
        zipCode: values.zipCode,
        email: email,
        phone: values.phone,
        isNewProspect: true,
        source: sourceName || 'portal',
        sourceType: sourceType || 'internet'
      };

      localStorage.setItem('userInfo', JSON.stringify(params));

      api
        .UserInfo(params)
        .then((response) => {
          setIsLoading(false);
          console.log('response in personalized', response);
          localStorage.setItem('phone', values.phone);
          if (response.status === 200 && response.data.user) {
            // verificationApi.sendVerificationCode({ contact: values.phone, channel: 'sms' }).then(
            // (code) => {
            //     if (code) {
            //         history.push('/confirm-phone-code')
            //     }
            // })
            // .catch((error) => {
            //     console.log(error);
            // })
            history.push('/schedule');
          } else {
            // history.push('/confirm-phone-code')
            history.push('/contact');
          }
        })
        .catch((err) => {
          console.log('error in handleSubmit', err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="main_field_area"></div>
      <div className="reg_box">
        <h1 className="reg_main_h1">Provide Your Information</h1>
        <p className="reg_p hideme">
          We have developed the largest selection of impact products with the most comprehensive warranties in the
          industry.
        </p>
        <form onSubmit={handleSubmit}>
          <p className="reg_input">Full Name</p>
          <label className="put-email">
            <input
              required
              className="field_area"
              placeholder="John Smith"
              type="text"
              name="name"
              onChange={onChangeText}
              value={values.name}
            />
          </label>

          <p className="reg_input">Address</p>
          <label className="put-email">
            <input
              required
              className="field_area"
              value={values.address}
              placeholder="123 Main Street"
              type="text"
              name="address"
              onChange={onChangeText}
            />
          </label>
          <p className="reg_input">Phone</p>
          <label className="put-email">
            <input
              value={values.phone}
              className="field_area"
              placeholder="555-555-2368"
              type="phone"
              name="phone"
              required
              onChange={onChangeText}
            />
          </label>
          <p className="reg_input">Zip Code</p>
          <label className="put-email">
            <input
              required
              className="field_area"
              value={values.zipCode}
              placeholder="12345"
              type="text"
              name="zipCode"
              onChange={onChangeText}
            />

            <button type="submit" className="email_btn">
              {isLoading ? (
                <Spinner style={{ width: '2rem', height: '2rem' }} color="light">
                  &nbsp;
                </Spinner>
              ) : (
                'Select Day & Time'
              )}
            </button>
          </label>
        </form>
      </div>
      <CopyrightText />
    </div>
  );
};

export default PersonalInfoComp;
