import React, { useState, useEffect } from 'react';
import '../assets/comStyles.css';
import { Spinner } from 'reactstrap';
import ReactCalendar from './DatePicker';
import Calendar from '../custom-components/Calendar';
import api from '../pages/schedule/Services';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { addDays } from 'date-fns';
import { trackScheduleEvent } from '../analytics';
import CopyrightText from './CopyrightText';
import PropTypes from 'prop-types';
import { backendApiUrl } from '../globals';

const TimeBlock = ({ startTime, endTime, appointmentDate, blockId, onClickHandler }) => {
  return (
    // <label class="form-check-label" for={blockId}>
    //     {`${startTime} - ${endTime}`}
    // </label>

    // <div className="app_time_picker">
    <label className="form-check-label app_time_picker" key={blockId}>
      <div className="app_circle" htmlFor={blockId}>
        <h6 className="app_time">{`${startTime} - ${endTime}`}</h6>
        <div className="app_btn_container">
          {/* <div className="form-check" onClick={(e) => {
                        e.preventDefault();
                        onClickHandler();
                        console.log(blockId);
                    }}> */}
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id={blockId}
            onClick={() => {
              onClickHandler();
            }}
          />
          {/* </div> */}
        </div>
      </div>
    </label>
    // </div>
  );
};

TimeBlock.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  appointmentDate: PropTypes.string.isRequired,
  blockId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

const ScheduleComp = () => {
  const slot1Ref = React.createRef();
  const slot2Ref = React.createRef();
  const slot3Ref = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState('00:00 AM');
  const email = localStorage.getItem('email');
  const phone = localStorage.getItem('phone');
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState('');
  const history = useHistory();
  const [availableDates, setAvailableDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState([]);
  const [filteredTimeBlocks, setFilteredTimeBlocks] = useState([]);
  // const [blockId, setBlockId] = useState('');
  const [blockData, setBlockData] = useState({});

  const formatDate = (date) => {
    // Format date
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // months from 1-12
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    const newDate = year + '-' + month + '-' + day;

    return newDate;
  };

  const handleChange = (newValue) => {
    const newDate = formatDate(newValue);
    setDate(newDate);

    setFilteredTimeBlocks(availableTimeBlocks.filter((block) => block.appointmentDate === newDate));

    // let tempAvailableSlots;
    // for (let i = 0; i < availableDates.length; i++) {
    //     if (availableDates[i].appointmentDate === newDate) {
    //         tempAvailableSlots = [];
    //         tempAvailableSlots.push(availableDates[i].slot1);
    //         tempAvailableSlots.push(availableDates[i].slot2);
    //         tempAvailableSlots.push(availableDates[i].slot3);
    //         setAvailableSlots(tempAvailableSlots);
    //     }
    // }
    // console.log(tempAvailableSlots); // TODO: Remove after testing
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      // let params = {
      //     name: localStorage.getItem('name'),
      //     appointment_time: time,
      //     appointment_date: date,
      //     phone: phone,
      //     email: email
      // }

      // Format date
      // var month = date.getUTCMonth() + 1; //months from 1-12
      // var day = date.getUTCDate();
      // var year = date.getUTCFullYear();
      // var newdate = year + "-" + month + "-" + day;

      const params = {
        email: localStorage.getItem('email'),
        ...JSON.parse(localStorage.getItem('userInfo')),
        blockId: blockData.blockId,
        startTime: blockData.startTime,
        appointment_date: date
      };

      axios
        .post(`${backendApiUrl}/appointment`, params)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            localStorage.setItem('user', JSON.stringify({ name: params.name }));
            localStorage.setItem(
              'appointment',
              JSON.stringify({
                date,
                startTime: blockData.startTime,
                endTime: blockData.endTime
              })
            );

            // track schedule for GA
            trackScheduleEvent(params);

            history.push('/appointment-status');
          } else if (response.data.message === 'Invalid zip code') {
            history.push('/contact');
          } else if (response.data.emailSent === false) {
            setError('Inavlid email');
          } else if (response.data.smsSent === false) {
            setError('Invalid SMS');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          history.push('/contact-error');
          // setError('Invalid zip code. Appointment not scheduled');
        });

      // let response = await axios.post(`${backendApiUrl}/user`, { email: params.email });
      // setIsLoading(false);
      // if (response.status == 200) {
      //     let user = response.data.user;

      //     axios.post(`${backendApiUrl}/appointment`, params).then(response => {
      //         localStorage.setItem('user', JSON.stringify(user));
      //         localStorage.setItem('appointment', JSON.stringify(response.data.appointment))
      //         history.push('/appointment-status')
      //     }).catch(error => {
      //         // localStorage.setItem('user', JSON.stringify(error.response.data.user))
      //         // localStorage.setItem('appointment', JSON.stringify(error.response.data.appointment))
      //         // history.push('/already-scheduled')
      //         setError('This appointment slot is not available');
      //     })
      // }

      /* api.Appointment(params).then((res) => {
                return res
            }).then((response) => {
                console.log('response in schedule', response)
                if (response?.appointment?.status === 200 && response?.user?.status === 200) {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    localStorage.setItem('appointment', JSON.stringify(response.appointment))
                    history.push('/appointment-status')
                } else {
                    console.log('im in already schedule_box')
                    let appointment = JSON.parse(localStorage.getItem('appointment'));
                    appointment.data.message = "Your Appointment is Already"
                    localStorage.setItem('appointment', JSON.stringify(appointment))
                    history.push('/already-scheduled', response.appointment)
                }
            }).catch((err) => {
                console.log('im in already schedule_box')
                    let appointment = JSON.parse(localStorage.getItem('appointment'));
                    appointment.data.message = "Your Appointment is Already"
                    localStorage.setItem('appointment', JSON.stringify(appointment))
                    history.push('/already-scheduled', response.appointment)
            })*/
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlockChange = ({ blockId, startTime, endTime }) => {
    setBlockData({
      blockId,
      startTime,
      endTime
    });
  };

  useEffect(() => {
    (async () => {
      // const response = await api.getAppointmentSlots(moment().format('YYYY-MM-DD'));
      // console.log(response.data);
      // setAvailableDates(response.data.slotInfo);

      const blockData = await api.getBlockTimeSlots(email);
      if (blockData.length > 0) {
        console.log(blockData);
        setAvailableTimeBlocks(blockData);
        // setFilteredTimeBlocks(
        //   blockData.filter((block) => block.appointmentDate === blockData[0].appointmentDate)
        // );
      } else {
        console.log('No blocks available');
      }
    })();
  }, []);

  return (
    <div>
      <Helmet>
        <script src="../assets/conversions.js" type="text/jsx" />
      </Helmet>
      <div className="main_field_area">
        <div className="reg_box">
          <div className="check_mark_main">
            <div className="d-flex align-items-center">
              <img src="../images/verified1.png" alt=""></img>
              <p>Select Date</p>
            </div>
            <div className="d-flex align-items-center">
              <img src="../images/verified2.png" alt=""></img>
              <p>Appt Scheduled</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="react_calender_container">
              {availableTimeBlocks.length <= 0 ? (
                <Spinner style={{ width: '6rem', height: '6rem' }} color="light">
                  &nbsp;
                </Spinner>
              ) : (
                <div style={{ width: '100%' }}>
                  <Calendar
                    onChangeHandler={handleChange}
                    firstTimeBlockDate={addDays(new Date(availableTimeBlocks[0].appointmentDate), 1)}
                  />
                  <div className="calender_area">
                    {filteredTimeBlocks.length > 0 ? (
                      filteredTimeBlocks.map((block, i) => (
                        <TimeBlock key={`tb-${i}`} {...block} onClickHandler={() => handleBlockChange(block)} />
                      ))
                    ) : (
                      <p style={{ textAlign: 'center' }}>No appointments available. Please select another date.</p>
                    )}
                  </div>
                </div>
              )}
              {/* <ReactCalendar value={date} onChange={handleChange} /> */}
            </div>
            {/* <div className="calender_area">
                            <div className="app_time_picker">
                                <div className="app_circle">
                                    <h6 className="app_time">10: 00 AM</h6>
                                    <div className="app_btn_container">
                                        <div ref={slot1Ref} className='app_btn'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (availableSlots[0]) {
                                                    setError('Slot unavailable for this date');
                                                } else {
                                                    setError('');
                                                    setTime('10:00 AM');
                                                    e.target.classList.toggle('selected_app_btn');
                                                    slot2Ref.current.classList.remove('selected_app_btn');
                                                    slot3Ref.current.classList.remove('selected_app_btn');
                                                }
                                            }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="app_circle">
                                    <h6 className="app_time">02: 00 PM</h6>
                                    <div className="app_btn_container">
                                        <div ref={slot2Ref} className='app_btn'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (availableSlots[1]) {
                                                    setError('Slot unavailable for this date');
                                                } else {
                                                    setError('');
                                                    setTime('02:00 PM')
                                                    e.target.classList.toggle('selected_app_btn');
                                                    slot1Ref.current.classList.remove('selected_app_btn');
                                                    slot3Ref.current.classList.remove('selected_app_btn');
                                                }
                                            }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="app_circle">
                                    <h6 className="app_time">06: 00 PM</h6>
                                    <div className="app_btn_container">
                                        <div ref={slot3Ref} className='app_btn'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (availableSlots[2]) {
                                                    setError('Slot unavailable for this date');
                                                } else {
                                                    setError('');
                                                    setTime('06:00 PM')
                                                    e.target.classList.toggle('selected_app_btn');
                                                    slot1Ref.current.classList.remove('selected_app_btn');
                                                    slot2Ref.current.classList.remove('selected_app_btn');
                                                }
                                            }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <label className="put-email">
              <span style={{ marginLeft: '20px' }}>{error}</span>
              <button type="submit" className="email_btn">
                {isLoading ? (
                  <Spinner style={{ width: '2rem', height: '2rem' }} color="light">
                    &nbsp;
                  </Spinner>
                ) : (
                  'Schedule Appointment'
                )}
              </button>
            </label>
          </form>
        </div>
        <CopyrightText />
      </div>
    </div>
  );
};
export default ScheduleComp;
