import { backendApiUrl } from '../../globals';

const axios = require('axios');

const loginServiceUrl = `${backendApiUrl}/user`;

class ApiService {
  async Appointment(data) {
    console.log('data in Appointment', data);
    let user;
    let response;
    try {
      user = await axios.post(`${loginServiceUrl}`, { email: data.email });
      response = await axios.post(`${backendApiUrl}/appointment`, data);
      return { appointment: response, user: user };
    } catch (e) {
      console.log('error in Appointment', e);
      return { e, user, response };
    }
  }

  async getAppointmentSlots(currentDate) {
    try {
      const response = await axios.get(`${backendApiUrl}/appointment-slots?currentDate=${currentDate}`); // TODO: Remove after testing
      return response;
    } catch (e) {
      return false;
    }
  }

  async getBlockTimeSlots(email) {
    try {
      const response = await axios.get(`${backendApiUrl}/block-time-slots?email=${email}`);
      return response.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default new ApiService();
