import React from 'react';
import moment from 'moment';
import '../assets/comStyles.css';
import { CopyrightText } from './CopyrightText';

const AppointmentStatus = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const appointment = JSON.parse(localStorage.getItem('appointment'));

  console.log(user);

  return (
    <div>
      <div className="schedule_area">
        <div className="schedule_box">
          <div className="check_mark_main">
            <div className="d-flex align-items-center">
              <img src="../images/verified2.png" alt=""></img>
              <p>Select Date</p>
            </div>

            <div className="d-flex align-items-center">
              <img src="../images/verified1.png" alt=""></img>
              <p>Appt Scheduled</p>
            </div>
          </div>
          <div className="calendar_black ">
            <img src="../images/Calendar.png" alt=""></img>
            {/* <p>Hello {user.name ? user.name : ''}!</p> */}
          </div>
          <div className="appointment_heading">
            <h1>
              {user.name ? user.name : ''},<br />
              Your appointment is scheduled for:
            </h1>
            {/* } <h1 style={{ color: '#F05523' }}>Scheduled for
                  <span style={{ color: 'black' }}>:</span>
              </h1> */}
          </div>
          <div className="calendar_color_area">
            {/* <img src="../images/calendardate.svg" alt=""></img> */}

            <div className="calendar_date_time">
              <p>{moment(appointment.date).format('MMMM Do YYYY')}</p>
              {/* <h2>{appointment.appointment_time.split(' ')[0]} <span> {appointment.appointment_time.split(' ')[1]}</span></h2> */}
              <h2>
                <span>{`${appointment.startTime} - ${appointment.endTime}`}</span>
              </h2>
            </div>
          </div>
          <div className="note_main_area">
            <div className="note_area">
              <img src="../images/QuestionMark.svg" alt=""></img>
              <p className="end_p">
                You&apos;ll receive an email and text with this appointment date and time. We&apos;ll call you
                momentarily to confirm your appointment.
              </p>
            </div>
          </div>
        </div>
        <CopyrightText />
      </div>
    </div>
  );
};

export default AppointmentStatus;
