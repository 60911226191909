import { useEffect } from 'react';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

/* Initialize analytics */
const analytics = Analytics({
  app: 'florida-window-door',
  version: 100,
  plugins: [
    googleAnalytics({
      trackingId: 'UA-19747576-1'
    })
  ]
});

/**
 * Use this hook to track a page.
 * @param {string} page page name
 */
export function usePageView(page) {
  useEffect(() => {
    if (!page) {
      console.error('Invalid page');
      return;
    }
    analytics.page(page);
  }, []);
}

/**
 * Track the schedule event
 * @param {object} payload event data.
 * @return {Promise<any>} a promise
 */
export async function trackScheduleEvent(payload) {
  return await analytics.track('schedule_event', payload);
}
