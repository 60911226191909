import axios from 'axios';
import { backendApiUrl } from '../../globals';

const loginServiceUrl = `${backendApiUrl}/user`;

class ApiService {
  async userByEmail(data) {
    try {
      console.log('userByEmail ====', data);
      const response = await axios.get(`${loginServiceUrl}`, {
        params: data
      });
      return response;
    } catch (e) {
      return e;
    }
  }

  async UserInfo(data) {
    try {
      const response = await axios.post(`${loginServiceUrl}/info`, data);
      return response;
    } catch (error) {
      console.log('error in add User Info', error);
      return error.response;
    }
  }

  async getPropsect({ email, referrer }) {
    try {
      const response = await axios({
        method: 'get',
        url: `${loginServiceUrl}/prospect?email=${email}&referrer=${encodeURIComponent(referrer)}`
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new ApiService();
